import React from "react"
import BackgroundImage from "gatsby-background-image"
import Title from "../title"
import { graphql, StaticQuery } from "gatsby"
import styles from "./page-header.module.scss"

export default function PageHeader({ title, image, data }) {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <BackgroundImage
          fluid={image || data.defaultImage.sharp.fluid}
          className={styles.pageHeader}
        >
          <div className={styles.overlay}></div>
          <Title headingLevel="h1" className={styles.title}>
            {title}
          </Title>
        </BackgroundImage>
      )}
    />
  )
}

export const query = graphql`
  query {
    defaultImage: file(
      relativePath: { eq: "ozel-civata-ozel-parcalar-2.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
